import { useRouter } from 'next/router';
import {
  PostPreviewPublic,
  PostType,
  SearchModePublic,
  SearchSortDirection,
  SearchSortField,
} from 'pn-backend';
import React, { FC, useEffect, useState } from 'react';

import { Chevron, SearchArrow } from '~assets';
import { Grid, HugeButton, Spinner, Text } from '~atoms';
import { DEFAULT_GRID_PROPS, MOBILE_FOOTER_HEIGHT, MOBILE_HEADER_HEIGHT } from '~constants';
import { Meta, Search as SearchString } from '~containers';
import { SearchItem } from '~postCards';
import { api } from '~services';
import { SearchProps } from '~types';
import { isDefined, TextColor } from '~utils';

export const Search: FC<SearchProps> = ({ count, hasMore: hasMoreResults, posts }) => {
  const {
    query: {
      dir: routerDir,
      mode: routerMode,
      sort: routerSort,
      term = '',
      types: routerTypes,
    } = {},
  } = useRouter();

  // Pagination state
  const [data, setData] = useState<PostPreviewPublic[] | null>(null);
  const [postCount, setPostCount] = useState(count);

  const [foundPosts, setFoundPosts] = useState(posts);
  const [searchTerm, setSearchTerm] = useState(term);

  const [offset, setOffset] = useState(30);
  const [error, setError] = useState<string>();

  const [hasMore, setHasMore] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const [dir, setDir] = useState<SearchSortDirection>(
    (routerDir as SearchSortDirection) || SearchSortDirection.Desc,
  );
  const [sort, setSort] = useState<SearchSortField>(
    (routerSort as SearchSortField) || SearchSortField.PublishedAt,
  );
  const [mode, setMode] = useState<SearchModePublic>(
    (routerMode as SearchModePublic) || SearchModePublic.Fulltext,
  );

  const types = Array.isArray(routerTypes) ? routerTypes : [routerTypes];

  const [postTypes, setPostTypes] = useState<PostType[]>(
    routerTypes
      ? (types as PostType[])
      : [PostType.NewsFull, PostType.Article, PostType.Card, PostType.Longread, PostType.Test],
  );

  const handleLoadMore = async () => {
    setIsLoadingMore(true);

    const response = await api.search({
      dir,
      mode,
      offset,
      sort,
      term: searchTerm as string,
      types: postTypes,
    });

    if (!response.data) {
      setError('Кажется, что-то пошло не так! Попробуйте еще раз!');
      setIsLoadingMore(false);
      return;
    }

    const morePosts = response.data.items ?? [];

    setData((prev) => {
      if (prev) {
        return [...prev, ...morePosts];
      }
      return null;
    });
    setHasMore(response.data.hasResults);
    setOffset(offset + 30);
    setError(undefined);
    setIsLoadingMore(false);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  useEffect(() => {
    if (posts) {
      setData(posts);
    }
  }, [posts]);

  useEffect(() => {
    setHasMore(hasMoreResults);
    setOffset(30);
  }, [hasMoreResults, term, routerDir, routerMode, routerSort, routerTypes]);

  useEffect(() => {
    setPostCount(count);
  }, [count]);

  useEffect(() => {
    setFoundPosts(posts);
  }, [posts]);

  const onDateClick = () => {
    if (sort === SearchSortField.PublishedAt) {
      if (dir === SearchSortDirection.Desc) {
        setDir(SearchSortDirection.Asc);
      } else {
        setDir(SearchSortDirection.Desc);
      }
    } else {
      setSort(SearchSortField.PublishedAt);
    }
  };

  const onScoreClick = () => {
    setSort(SearchSortField.Score);
  };

  return (
    <>
      <Meta noIndex />
      <Grid
        {...DEFAULT_GRID_PROPS}
        $background="white"
        $color="black"
        $padding="0 0 40px"
        $xl={{ $gap: '20px', $padding: '0 15px 30px' }}
        $sm={{
          $display: 'flex',
          $flexDirection: 'column',
          $justifyContent: 'flex-start',
          $minHeight: `calc(100vh - ${MOBILE_HEADER_HEIGHT} - ${MOBILE_FOOTER_HEIGHT})`,
          $padding: '0 15px 40px',
        }}
      >
        <Grid>
          <SearchString
            setFoundPosts={setFoundPosts}
            setSearchTerm={setSearchTerm}
            setMode={setMode}
            setPostTypes={setPostTypes}
            // setPostCount={setPostCount}
            dir={dir}
            sort={sort}
            mode={mode}
            postTypes={postTypes}
          />
        </Grid>
        <Grid $minHeight="30vh">
          {!searchTerm && (
            <Text $size={28} $lineHeight={30} $weight={600}>
              введите строку для поиска
            </Text>
          )}
          {searchTerm && foundPosts?.length < 1 && (
            <Text $size={28} $lineHeight={30} $weight={600}>
              ничего не нашлось. попробуйте по-другому
            </Text>
          )}
          {foundPosts?.length > 0 && (
            <>
              <Grid
                {...DEFAULT_GRID_PROPS}
                $padding="0 0 30px"
                $display="flex"
                $alignItems="center"
                $justifyContent="space-between"
                $sm={{
                  $alignItems: 'flex-start',
                  $flexDirection: 'column',
                  $gap: '20px',
                  $justifyContent: 'flex-start',
                  $margin: '0',
                  $marginBottom: '20px',
                  $padding: '0',
                }}
              >
                <Text $size={28} $lineHeight={30} $weight={600}>
                  {`найдено результатов: ${postCount}`}
                </Text>

                <Grid $display="flex" $gap="20px">
                  <Grid
                    $cursor="pointer"
                    onClick={onDateClick}
                    $display="flex"
                    $gap="5px"
                    $alignItems="center"
                    $hoverColor="var(--color-primary)"
                  >
                    <Text
                      $size={20}
                      $lineHeight={22}
                      $weight={500}
                      $cursor="pointer"
                      $color={
                        sort === SearchSortField.PublishedAt ? ('primary' as TextColor) : undefined
                      }
                    >
                      по дате
                    </Text>
                    <Grid
                      $transition="transform 0.3s ease"
                      $transform={
                        dir === SearchSortDirection.Asc ? 'rotate(180deg)' : 'rotate(0deg)'
                      }
                    >
                      <SearchArrow
                        style={{
                          color:
                            sort === SearchSortField.PublishedAt
                              ? 'var(--color-primary)'
                              : undefined,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Text
                    $size={20}
                    $lineHeight={22}
                    $weight={500}
                    $cursor="pointer"
                    $hoverColor="var(--color-primary)"
                    $color={sort === SearchSortField.Score ? ('primary' as TextColor) : undefined}
                    onClick={onScoreClick}
                  >
                    по соответствию
                  </Text>
                </Grid>
              </Grid>
              <Grid $gap="20px">
                {data && data.map((item, index) => <SearchItem key={index} post={item} withTag />)}
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      {hasMore && (
        <HugeButton
          $lg={{
            $borderWidth: '3px',
            $fontSize: '24px',
            $height: '80px',
            $lineHeight: '26px',
          }}
          $sm={{
            $borderWidth: '2px',
            $fontSize: '18px',
            $height: '60px',
            $lineHeight: '20px',
            $margin: '0 auto',
          }}
          onClick={handleLoadMore}
        >
          {isLoadingMore ? (
            <Spinner />
          ) : (
            <Grid $justifyItems="center" $gap="16px">
              <Grid $gridAutoFlow="column" $columnGap="10px" $alignItems="center">
                <Text
                  $size={32}
                  $weight={600}
                  $lg={{ $lineHeight: 26, $size: 24 }}
                  $sm={{ $lineHeight: 20, $size: 18 }}
                >
                  еще результаты
                </Text>
                <Grid $width="32px" $height="32px" $sm={{ $height: '20px', $width: '20px' }}>
                  <Chevron />
                </Grid>
              </Grid>
              {isDefined(error) && (
                <Grid>
                  <Text $size={16}>{error}</Text>
                </Grid>
              )}
            </Grid>
          )}
        </HugeButton>
      )}
    </>
  );
};
